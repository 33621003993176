import useGetMessage from "hooks/useGetMessage"
import useTyping from "hooks/useTyping/useTyping"
import React, { useEffect, useState } from "react"
import { merge } from "rxjs"
import { tap } from "rxjs/operators"
import edit$ from "../../../../observers/edit$"
import reply$ from "../../../../observers/reply$"
import { EDIT_MESSAGE, NO_SELECTED_MESSAGE, REPLY_MESSAGE } from "./constants"
import { useModesApi } from "./useModesApi"

export function useFooterApi() {
  const [mode, setMode] = useState(NO_SELECTED_MESSAGE)
  const [messageID, setMessageID] = React.useState<string | null>(null)

  const getMessage = useGetMessage()
  const [hasFocus, setHasFocus] = useState(false)

  useEffect(() => {
    const selectedMessage$ = merge(
      reply$.pipe(tap(({ messageID }) => messageID && setMode(REPLY_MESSAGE))),
      edit$.pipe(tap(({ messageID }) => messageID && setMode(EDIT_MESSAGE)))
    )

    const sub = selectedMessage$.subscribe(({ messageID }) => {
      setMessageID(messageID)
      !messageID && setMode(NO_SELECTED_MESSAGE)
    })

    return () => sub.unsubscribe()
  }, [])

  const api = useModesApi({ selectedMessageID: messageID })
  const { stopTyping, startTyping } = useTyping()

  React.useEffect(() => {
    return () => {
      stopTyping()
    }
  }, [])

  return React.useMemo(() => {
    return {
      mode,
      selectedMessage: messageID ? getMessage({ messageID }) : null,
      ...api[mode],

      submitMessage(message: string) {
        api[mode].submitMessage(message)
        api[mode].discardMessageSelection()
      },

      onFiles(files: File[]) {
        api[mode].onFiles(files)
        api[mode].discardMessageSelection()
      },

      onChange(text: string) {
        if (!text) return stopTyping()
        return hasFocus && startTyping()
      },

      onBlur() {
        setHasFocus(false)
        stopTyping()
      },

      onFocus() {
        setHasFocus(true)
      },
    }
  }, [mode, messageID, api])
}
