import Hook from "components/Hook/Hook"
import Room from "components/Room/Room"
import { Shop } from "components/Shop/Shop"
import UseEffect from "components/UseEffect/UseEffect"
import { RootContainerAPI } from "hooks/useRootContainerAPI"
import { PageBoardItemType } from "lib/graphql/types"
import _ from "lodash"
import React from "react"
import { ItemTemplate } from "../../../ItemTemplate/ItemTemplate"
import RoomPageBoardItemContext from "../../contexts/RoomPageBoardItemContext"
import useRoomPageBoardItemAPI from "../../hooks/useRoomPageBoardItemAPI"
import {
  RoomPageBoardItemAPIProps,
  ShoppingInitialPageParams,
} from "../../types"
import { RoomShoppingPage } from "../RoomShoppingPage/RoomShoppingPage"
import { CartPage } from "../Shopping/CartPage/CartPage"
import { useRenderMessageAttachments } from "./hook/useRenderMessageAttachments"
import { useRenderMessageMenuItems } from "./hook/useRenderMessageMenuItems"

export default function RoomPageBoardItemProvider(
  props: RoomPageBoardItemAPIProps & { children: React.ReactNode }
) {
  const [__, setRootContainer] = React.useState<RootContainerAPI | null>(null)

  const identifier = React.useMemo(() => {
    if (props.identifier) return props.identifier

    if (!props.entry?.room?.id) return null

    return { roomID: props.entry?.room?.id }
  }, [props.entry?.room?.id, props.identifier])

  const renderMessageAttachments = useRenderMessageAttachments()

  if (!identifier) return null

  return (
    <ItemTemplate.Provider
      onClose={props.onClose}
      {...(() => {
        if (
          (props.initialParams?.openedPage as ShoppingInitialPageParams)
            ?.page === "SHOPPING"
        ) {
          return {
            TwoPagesAPIProps: {
              openedSecondPage: {
                Page: RoomShoppingPage,
              },
            },
          }
        }
      })()}
    >
      <ItemTemplate.Context.Consumer>
        {(templateAPI) => {
          return (
            <Hook
              hook={useRenderMessageMenuItems}
              hookProps={{ twoPagesAPI: templateAPI.twoPagesAPI }}
            >
              {(renderMessageMenuItems) => {
                return (
                  <Room.Provider
                    identifier={identifier}
                    initialTopMostItemIndex={props.initialTopMostItemIndex}
                    renderMessageAttachments={renderMessageAttachments}
                    renderMessageMenuItems={renderMessageMenuItems}
                  >
                    <Room.Context.Consumer>
                      {(roomAPI) => {
                        const partnerItemID =
                          props.entry?.significativeRelations?.find(
                            (a) => a.type === PageBoardItemType.Partner
                          )?.itemID || null
                        const hasFixedPartnerID = !!partnerItemID

                        return (
                          <Shop.Provider cartID={roomAPI.room?.cartID || ""}>
                            <CartPage.Provider
                              partnerIdIsEditable={!hasFixedPartnerID}
                              partnerItemID={partnerItemID}
                            >
                              <Room.SearchMessages.Provider>
                                <ItemTemplate.Context.Consumer>
                                  {(api) => {
                                    return (
                                      <>
                                        <UseEffect
                                          deps={[api.rootContainer]}
                                          effect={() =>
                                            setRootContainer(api.rootContainer)
                                          }
                                        ></UseEffect>
                                        <Hook
                                          hook={useRoomPageBoardItemAPI}
                                          hookProps={{
                                            twoPagesAPI: api.twoPagesAPI,
                                            roomAPI,
                                            ..._.omit(props, "children"),
                                          }}
                                        >
                                          {(api) => {
                                            return (
                                              <RoomPageBoardItemContext.Provider
                                                value={api}
                                              >
                                                {props.children}
                                              </RoomPageBoardItemContext.Provider>
                                            )
                                          }}
                                        </Hook>
                                      </>
                                    )
                                  }}
                                </ItemTemplate.Context.Consumer>
                              </Room.SearchMessages.Provider>
                            </CartPage.Provider>
                          </Shop.Provider>
                        )
                      }}
                    </Room.Context.Consumer>
                  </Room.Provider>
                )
              }}
            </Hook>
          )
        }}
      </ItemTemplate.Context.Consumer>
    </ItemTemplate.Provider>
  )
}
