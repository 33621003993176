import React from "react"
// import IconButton from "components/IconButton/IconButton"
import CartButtons from "../../CartButtons/CartButtons"
import ShopArticleCard, {
  ShopArticleProps,
} from "../../ShopArticleCard/ShopArticleCard"
import { ShopContext } from "../ShopContext"

export function ArticleCard(
  props: Omit<ShopArticleProps, "actionsButtons"> & {
    renderActions?: (props: { cartButtons: React.ReactNode }) => React.ReactNode
  }
) {
  const cart = React.useContext(ShopContext).cartApi

  const cartButtons = (
    <CartButtons
      addMoreOne={() => cart.addMoreOne.action({ articleID: props.article.id })}
      remove={() => {
        cart.remove.action({ articleID: props.article.id })
      }}
      IconButtonProps={{ size: "small" }}
      quantity={cart.getItemQuantity({ articleID: props.article.id })}
    ></CartButtons>
  )

  return (
    <ShopArticleCard
      {...props}
      {...(props.renderActions
        ? { actionsButtons: props.renderActions({ cartButtons: cartButtons }) }
        : {
            actionsButtons: (
              <div
                className="actions-buttons"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {cartButtons}
              </div>
            ),
          })}
    />
  )
}
