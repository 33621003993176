import { gql } from "@apollo/client";
import fragmentTypes from "lib/fragmentTypes/fragmentTypes.json"
import _f from "lodash/fp"

/**
 * Discovering the concrete type of a node given its interface
 */
export default function getApolloInterfaceTypename(
  { interfaceName, id },
  client
) {
  const __typename = _f.flow(
    _f.get("__schema.types"),
    _f.find(type => {
      if (type.kind !== "INTERFACE") return false
      if (type.name != interfaceName) return false

      return true
    }),
    _f.defaultTo({}),
    _f.get("possibleTypes"),
    _f.map("name"),
    _f.find(__typename => nodeExists({ __typename, id }, client))
  )(fragmentTypes)

  return __typename
}

function nodeExists({ __typename, id }, client) {
  const node = client.readFragment({
    id: `${__typename}:${id}`,
    fragment: gql`
      fragment DiscoveringFragment on ${__typename}{
        id
      }
    `
  })

  return !!node
}
