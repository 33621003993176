import { ShopContext, ShopProvider } from "./ShopContext"
import { ArticleCard } from "./components/ArticleCard"
import { ArticlesGrid } from "./components/ArticlesGrid/ArticlesGrid"
import { CartButtons } from "./components/CartButtons/CartButtons"
import { CartItemQuantity } from "./components/CartItemQuantity/CartItemQuantity"

export const Shop = {
  Context: ShopContext,
  Provider: ShopProvider,
  CartButtons,
  CartItemQuantity,
  ArticleCard,
  ArticlesGrid: ArticlesGrid,
}
