import { useI18n } from "contexts/i18nContext/i18nContext"
import {
  Message_TextMessage_Fragment,
  RepliedMessage_TextMessage_Fragment,
} from "lib/graphql/operations"
import React from "react"
import { Preview } from "./Preview"
import getAttachmentImage from "./util/getAttachmentImage"
import getText from "./util/getText"

/** @deprecated */
export default TextMessagePreview

export function TextMessagePreview(props: {
  message: RepliedMessage_TextMessage_Fragment | Message_TextMessage_Fragment
}) {
  const message = props.message

  const text = React.useMemo(() => getText(message), [message])
  const thumb = React.useMemo(() => getAttachmentImage(message), [message])
  const t = useI18n()

  if (message.id === "phbDbfsfQLZ45JHsf") {
    console.log(text, thumb?.src)
  }

  if (!message) return null

  if (!text && !thumb?.src) return null

  const previewBasicProps = {
    description: text || t["image"] || "",
    title: message?.author?.name || "",
    src: thumb?.src || "",
  }

  return <Preview {...previewBasicProps} />
}
