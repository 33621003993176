import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import useOnChange from "./useOnChange"
/**
 * `useTrueFalse` is a custom React hook that manages a boolean state with utility functions for
 * updating the state to true, false or toggling its value. This hook is useful for handling state
 * transitions in components like toggles, checkboxes or buttons with two states.
 *
 * The hook accepts the following parameters:
 * - `value`: An optional initial boolean value for the state.
 * - `options`: An optional object containing the following options:
 *   - `isBidirectional`: A flag to enable two-way binding of the state (default: false).
 *
 * The hook internally uses the `useOnChange` hook to keep the state in sync if the `value` prop
 * changes and two-way binding is enabled (`isBidirectional` option set to true).
 *
 * The hook returns an object with utility functions and the current state:
 * - `isTrue`: The current boolean state (true).
 * - `isFalse`: The negation of the current boolean state (false).
 * - `setTrue`: A function to set the state to true.
 * - `setFalse`: A function to set the state to false.
 * - `set`: A function to directly set the state to a given boolean value.
 * - `toggle`: A function to toggle the state.
 * - `setIsTrue`: A function to set the state, identical to `set`.
 *
 * @param {boolean} [value] - Optional initial boolean value for the state.
 * @param {Object} [options] - Optional configuration object.
 * @param {boolean} [options.isBidirectional] - Flag to enable two-way binding of the state (default: false).
 * @returns {Object} An object containing the current state and utility functions.
 *
 * @example
 * const MyComponent = () => {
 *   const { isTrue, setTrue, setFalse, toggle } = useTrueFalse(false);
 *
 *   return (
 *     <>
 *       <button onClick={setTrue}>Set True</button>
 *       <button onClick={setFalse}>Set False</button>
 *       <button onClick={toggle}>Toggle</button>
 *       <p>State: {isTrue ? "True" : "False"}</p>
 *     </>
 *   );
 * }
 */

export default function useTrueFalse(
  value?: boolean,
  options?: { isBidirectional?: boolean }
) {
  const [isTrue, setIsTrue] = React.useState<boolean>(
    typeof value === "undefined" ? false : value
  )

  useOnChange({
    value: !!value,
    onChange: setIsTrue,
    disabled: !options?.isBidirectional,
  })

  const setTrue = React.useCallback(() => {
    setIsTrue(() => true)
  }, [])

  const setFalse = React.useCallback(() => {
    setIsTrue(() => false)
  }, [])

  const toggle = React.useCallback(() => {
    setIsTrue((v) => !v)
  }, [])

  return useMemoAPI({
    isTrue,
    isFalse: !isTrue,
    setTrue,
    setFalse,
    set: setIsTrue,
    toggle,
    setIsTrue,
  })
}
