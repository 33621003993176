import React from "react"
import { KanbanActivityFragment } from "lib/graphql/operations"
import { useI18n } from "contexts/i18nContext/i18nContext"
import Shareable from "components/Shareable/Shareable"
import { ShareableType } from "types/type"
import LinkButton from "components/LinkButton/LinkButton"
import { RoomContext } from "components/Room/contexts/room"
import { quote } from "contexts/i18nContext/defaultTranslations"
import ShareableLink from "../../../../../../../../../../../SameOriginLink/ShareableLink"
import useCurrentWorkspace from "../../../../../../../../../../../../hooks/useCurrentWorkspace/useCurrentWorkspace"

export default function DeleteCard(props: {
  cardLink: React.ReactNode
  activity: KanbanActivityFragment
}) {
  const t = useI18n()

  if (props.activity.__typename !== "KanbanDeleteCardActivity") return null

  const link = (
    <LinkButton
      description={props.activity.deletedCardTitle || t["card-without-title"]}
      iconName={Shareable.icons[ShareableType.KanbanCard]}
      disabled
    ></LinkButton>
  )
  return (
    <>
      <span>{t["iveDeletedATaskWithTitle"](link)}</span>
    </>
  )
}

export function MoveCardBetweenColumns(props: {
  cardLink: React.ReactNode
  activity: KanbanActivityFragment
}) {
  const t = useI18n()
  const roomAPI = React.useContext(RoomContext)
  const userID = useCurrentWorkspace()?.user?.id || ""

  if (props.activity.__typename !== "KanbanMoveCardBetweenColumnsActivity")
    return null

  const fromRoom = props.activity.fromRoom
  const toRoom = props.activity.toRoom

  const roomID = roomAPI.roomID

  if (fromRoom?.roomID === toRoom?.roomID) {
    const fromColumn = props.activity.fromColumn
    const toColumn = props.activity.toColumn

    const fromColumnTitle = fromColumn?.title || ""
    const toColumnTitle = toColumn?.title || ""

    const ok = t["iveMovedATaskBetweenColumns"](
      props.cardLink,
      quote(fromColumnTitle),
      quote(toColumnTitle)
    )

    return ok
  } else {
    const thisRoomIsOrigin = roomID === fromRoom?.id
    const thisRoomIsDestination = roomID === toRoom?.id

    const renderRoomTitle = (roomID: string) => {
      return (
        <ShareableLink
          UseShareableProps={{
            shareableVariables: {
              shareableID: roomID,
              shareableType: ShareableType.Room,
              sharerID: userID,
            },
          }}
        ></ShareableLink>
      )
    }

    if (thisRoomIsOrigin) {
      const roomTitle = renderRoomTitle(toRoom?.roomID || "")
      const ok = t["iveMovedATaskToThisRoom"](props.cardLink, roomTitle)

      return ok
    }

    if (thisRoomIsDestination) {
      const roomTitle = renderRoomTitle(fromRoom?.roomID || "")
      return t["iveMovedATaskFromThisRoom"](props.cardLink, roomTitle)
    }

    return null
  }
}
