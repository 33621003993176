import React from "react"
import clsx from "clsx"

export default function StopPropagation(props: {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}) {
  return (
    <div
      className={clsx("stop-propagation", props.className)}
      onClick={(e) => e.stopPropagation()}
      style={props.style}
    >
      {props.children}
    </div>
  )
}
