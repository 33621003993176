import { RoomAPIInput } from "components/Room/hooks/useRoomAPI/useRoomAPI"
import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import I18nContext from "contexts/i18nContext/i18nContext"
import { excludeNullable } from "lib/excludeNullable"
import { UserType } from "lib/graphql/types"
import React from "react"
import { AssistantMessageInspectPage } from "../../AssistantMessageInspectPage/AssistantMessageInspectPage"

export function useRenderMessageMenuItems(props: { twoPagesAPI: TwoPagesAPI }) {
  type Render = Exclude<RoomAPIInput["renderMessageMenuItems"], undefined>

  const render = React.useCallback<Render>(
    (p) => {
      const isBot = p.message.author?.type === UserType.Bot

      return [
        (() => {
          if (!isBot) return null

          return {
            label: (
              <I18nContext.Consumer>{(t) => t["inspect"]}</I18nContext.Consumer>
            ),
            icon: "code",
            onClick: () => {
              props.twoPagesAPI.openHalfPage(function InspectPage(args) {
                return (
                  <AssistantMessageInspectPage
                    message={p.message}
                    onClose={args.onClose}
                  />
                )
              })
            },
          }
        })(),
        ...p.defaultItems,
      ].filter(excludeNullable)
    },
    [props.twoPagesAPI.openHalfPage]
  )

  return render
}
