import "./styles/index.scss"
import "moment/locale/de"
import "util/windowUtilities"

import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"
import Tree from "Tree"
import HighestZIndexLayer from "components/HighestZIndexLayer/HighestZIndexLayer"

if (process.env.NODE_ENV === "development") {
  const script = document.createElement("script")
  script.src = "/react-devtools"
  document.body.insertBefore(script, document.body.firstChild)
}

export const app = (
  <>
    <Tree></Tree>
    <HighestZIndexLayer.Root></HighestZIndexLayer.Root>
    {/* <h1>hello</h1> */}
  </>
)

ReactDOM.render(app, document.getElementById("root"))

serviceWorker.register()
