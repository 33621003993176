import TemplatePage from "components/PageBoard/components/TemplatePage/TemplatePage"
// import TemplatePage from "components/PageBoard/components/TemplatePage/contexts/TemplatePageContext"
import I18nContext from "contexts/i18nContext/i18nContext"
import { AssistantInspectProvider, InputProps } from "./AssistantInspectContext"
import { Body } from "./Body"

export function AssistantMessageInspectPage(props: InputProps) {
  return (
    <AssistantInspectProvider message={props.message} onClose={props.onClose}>
      <I18nContext.Consumer>
        {(t) => {
          return (
            <TemplatePage.Provider onClose={props.onClose}>
              <TemplatePage.Root
                EnhancedPageProps={{
                  title: t["inspect"],
                  body: <Body style={{ height: "100%" }}></Body>,
                  onClose: props.onClose,
                  onCloseIconName: "arrow_back",
                }}
              ></TemplatePage.Root>
            </TemplatePage.Provider>
          )
        }}
      </I18nContext.Consumer>
    </AssistantInspectProvider>
  )
}
