import React from "react"
import useRoomPageBoardItemAPI from "../hooks/useRoomPageBoardItemAPI"

const RoomPageBoardItemContext = React.createContext(
  {} as ReturnType<typeof useRoomPageBoardItemAPI>
)

RoomPageBoardItemContext.displayName = "RoomPageBoardItemContext"

export default RoomPageBoardItemContext
