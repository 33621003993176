import { Typography } from "@material-ui/core"
import { codeMatBackgroundColor } from "components/CodeMat/CodeMat"
import React from "react"
import { StyledProps } from "types/type"
import clsx from "clsx"

export function Mat(props: StyledProps & { text: string }) {
  const split = props.text.split("\n")

  const nonBlankFirstIndex = split.findIndex((b) => !!b)

  return (
    <div
      className={clsx(props.className, "mat")}
      style={{
        padding: 16,
        // margin: 16,
        // marginTop: 0,
        width: "100%",
        backgroundColor: codeMatBackgroundColor,
        ...props.style,
      }}
    >
      {split.slice(nonBlankFirstIndex || 0).map((b, index) => (
        <Typography variant="body2" key={index}>
          <code
            style={{
              visibility: !!b ? "visible" : "hidden",
              lineHeight: 1.8,
            }}
          >
            {b || "oi"}
          </code>
        </Typography>
      ))}
    </div>
  )
}
