import MomentUtils from "@date-io/moment"

import { Snackbar } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider } from "@material-ui/styles"
import * as Sentry from "@sentry/react"
import ActiveWorkspaceIDListener from "components/ActiveWorkspaceIDListener/ActiveWorkspaceIDListener"
import ConnectionStatus from "components/ConnectionStatus/ConnectionStatus"
import PWAInstallation from "components/PWAInstallation/PWAInstallation"
import { ActiveRoomProvider } from "contexts/activeRoom"
import { DrawerProvider } from "contexts/drawerContext/drawerContext"
import { ErrorContext, ErrorProvider } from "contexts/error"
import { I18nProvider } from "contexts/i18nContext/i18nContext"
import { SideSheetDocumentProvider } from "contexts/sideSheetDocument/sideSheetDocument"
import { setActiveWorkspaceID } from "lib/activeWorkspaceID"
import React from "react"
import { Route } from "react-router-dom"
import AppContent from "./pages/App"
import theme from "./theme"
// import { Integrations } from "@sentry/tracing"
// import * as Sentry from "@sentry/react"

// import isProduction from "lib/isProduction"
import { ReloadAppAfterSomeTimeInactive } from "ReloadAppAfterSomeTimeInactive"
import AppVisibility from "components/AppVisibility/AppVisibility"
import ComposeProviders from "components/ComposeProviders/ComposeProviders"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import KeyListeners from "components/KeyListeners/KeyListeners"
import WindowSize from "components/WindowSize/WindowSize"
import { createBrowserHistory } from "history"
import { enableSentry } from "lib/config"
import isProduction from "lib/isProduction"
import { Router } from "react-router"
import ApolloSetup from "./components/ApolloSetup/ApolloSetup"
import { LoggedUser } from "components/LoggedUser/LoggedUser"

// enableSentry &&
// Sentry.init({
//   dsn: "https://ad5df5bb026945358cb3c88c460de4d4@sentry.365my.biz/2",

//   // "https://723cbcd2559f44f2af133ec37dcece7b@sentry.365my.biz/4",
//   integrations: [
//     new Integrations.BrowserTracing(),
//     new Sentry.Integrations.Breadcrumbs({ console: false }),
//   ],
//   // We recommend adjusting this value in production, or using tracesSampler for finer control
//   tracesSampleRate: 1.0,
//   environment: process.env.NODE_ENV,
//   release: process.env.REACT_APP_SENTRY_RELEASE || "0.0.0.0",
// })
//...

const history = createBrowserHistory()

const startSentry = () => {
  if (!enableSentry) return

  const variables = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE || "1.0.0",
  }

  Sentry.init({
    // dsn: "https://6acc1635a49cd5feeaf656109111356f@sentry.365my.biz/4",
    // dsn: "https://6acc1635a49cd5feeaf656109111356f@sentry.365my.biz/4",
    // dsn: process.env.REACT_APP_SENTRY_DSN,
    // release: process.env.REACT_APP_SENTRY_RELEASE || "1.0.0",
    ...variables,
    environment: isProduction
      ? window.location.host.includes("stag")
        ? "stage"
        : "production"
      : "development",
    integrations: [
      // new Sentry.BrowserTracing({
      //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //   tracePropagationTargets: [
      //     "ngrok-free.app",
      //     /^https:\/\/yourserver\.io\/api/,
      //     /^https:\/\/yourserver\.io\/api/,
      //   ],
      // }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const Providers = [
  function SentryStart({ children }: { children: React.ReactNode }) {
    React.useEffect(() => {
      startSentry()
    }, [])

    return <>{children}</>
  },
  WindowSize.Provider,
  DeviceObserver.Provider,
  KeyListeners.Provider,
  ConnectionStatus.Provider,
  AppVisibility.Provider,
  function BrowserRouterProvider({ children }: { children: React.ReactNode }) {
    return <Router history={history}>{<>{children}</>}</Router>
  },
  ActiveRoomProvider,
  ApolloSetup.Provider,
  I18nProvider,
  PWAInstallation.Provider,
  LoggedUser.Provider,
]

// function useA() {
//   // const a = useWorkspacesQuery({ fetchPolicy: "" })
//   const a = useQuery(WorkspacesDocument, { fetchPolicy: "cache-and-network" })
//   console.log(a)
//   const [, setBingo] = React.useState(0)
//   const client = useApolloClient()

//   React.useEffect(() => {
//     // clientInformation.
//     // setTimeout(() => {
//     //   const a = client
//     //     .refetchQueries({
//     //       // optimistic: true,
//     //       updateCache: (cache) => {
//     //         console.log(WorkspacesDocument)
//     //         cache.evict({ fieldName: "workspaces" })
//     //       },
//     //       onQueryUpdated: (a) => {
//     //         console.log(a)
//     //         return true
//     //       },
//     //     })
//     //     .then((a) => {
//     //       console.log(a)
//     //     })
//     // }, 5000)
//     // setInterval(() => {
//     //   // setBingo(Math.random())
//     // }, 1000)
//   }, [client])

//   return null
// }

export default function Tree() {
  return (
    <ComposeProviders Providers={Providers}>
      <ReloadAppAfterSomeTimeInactive></ReloadAppAfterSomeTimeInactive>
      <React.Fragment>
        <ActiveWorkspaceIDListener
          onChangeActiveWorkspaceID={setActiveWorkspaceID}
        />

        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
            <SideSheetDocumentProvider>
              <DrawerProvider>
                <ErrorProvider>
                  <ErrorContext.Consumer>
                    {({ errorMessage, setErrorMessage }) => {
                      return (
                        <>
                          <Route component={AppContent} />
                          <Snackbar
                            open={!!errorMessage}
                            onClose={() => setErrorMessage(null)}
                            message={errorMessage}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          />
                        </>
                      )
                    }}
                  </ErrorContext.Consumer>
                </ErrorProvider>
              </DrawerProvider>
            </SideSheetDocumentProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </React.Fragment>
    </ComposeProviders>
  )
}
