import { useRelatedPageBoardItems } from "components/PageBoard/hooks/useRelatedPageBoardItems"
import { TwoPagesAPIInput } from "components/TwoPages/hooks/useTwoPagesAPI"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  NewPageBoardItemFragment,
  usePageBoardItemQuery,
} from "lib/graphql/operations"
import { PageBoardItemParams, PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { InitialParams } from "../PageBoardItem"
import {
  FetchProps,
  useFetchPageBoardItem,
} from "../components/FetchPageBoardItem/FetchPageBoardItem"

export type GetItemParams<
  Type extends PageBoardItemParams["type"] = PageBoardItemParams["type"]
> = {
  item?: NewPageBoardItemFragment | null
  itemParams?: { type: Type; itemID: PageBoardItemParams["itemID"] }
  fetchItemProps?: FetchProps<Type>
}

export type PageBoardItemApiInput<
  Type extends PageBoardItemParams["type"] = PageBoardItemParams["type"]
> = {
  initialParams?: Type extends keyof InitialParams ? InitialParams[Type] : never
  twoPagesApiMandatoryLayout?: TwoPagesAPIInput["mandatoryLayout"]
} & GetItemParams<Type>

export function usePageBoardItemApi<
  Type extends PageBoardItemParams["type"] = PageBoardItemParams["type"]
>(props: PageBoardItemApiInput<Type>) {
  const item = useItem({
    item: props.item,
    itemParams: props.itemParams,
    fetchItemProps: props.fetchItemProps,
  })

  const p = {
    itemID: item?.itemID,
    type: item?.type,
  }
  const itemParams = React.useMemo(
    () => (p.itemID && p.type ? (p as PageBoardItemParams) : null),
    [p.itemID, p.type]
  )

  const related = useRelatedPageBoardItems({ itemParams }).related

  return useMemoAPI({ ...props, item, related, itemParams })
}

function useItem<
  Type extends PageBoardItemParams["type"] = PageBoardItemParams["type"]
>(props: {
  item?: NewPageBoardItemFragment | null
  itemParams?: PageBoardItemParams | null
  fetchItemProps?: FetchProps<Type> | null
}) {
  const p = {
    itemID: props.itemParams?.itemID || "",
    type: props.itemParams?.type || PageBoardItemType.Room,
  }

  const variables = { ...p, workspaceID: useActiveResources().workspaceID }

  const queryIsAllowed = Object.values(variables).some((v) => !v)

  const query = usePageBoardItemQuery({
    variables: variables,
    fetchPolicy: "cache-and-network",
    skip: !!props.item || !!props.fetchItemProps || queryIsAllowed,
  })

  const fetchedItem = useFetchPageBoardItem({
    type: props.fetchItemProps?.type,
    params: props.fetchItemProps?.params,
    skip: !props.fetchItemProps || !!props.item || !queryIsAllowed,
  })

  return (
    query.data?.pageBoardItem ||
    fetchedItem ||
    props.item ||
    query.previousData?.pageBoardItem ||
    null
  )
}
