import RoomPageBoardItemProvider from "./components/RoomPageBoardItemProvider/RoomPageBoardItemProvider"
import RoomPageBoardItemRoot from "./components/RoomPageBoardItemRoot/RoomPageBoardItemRoot"
import { RoomPageBoardItemAPIProps } from "./types"

const RoomPageBoardItem = {
  Default: RoomPageBoardItemDefault,
  Provider: RoomPageBoardItemProvider,
  Root: RoomPageBoardItemRoot,
}

export type { RoomPageBoardItemAPIProps as RoomPageBoardItemProps } from "./types"

export default RoomPageBoardItem

function RoomPageBoardItemDefault(props: RoomPageBoardItemAPIProps) {
  return (
    <RoomPageBoardItemProvider {...props}>
      <RoomPageBoardItemRoot></RoomPageBoardItemRoot>
    </RoomPageBoardItemProvider>
  )
}
