import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import useOnChange from "hooks/useOnChange"

export default function ViewsWithTransition(props: {
  views: React.ReactNode[]
  className?: string
  style?: React.CSSProperties
  transitionInMs?: number
}) {
  const [moved, setMoved] = React.useState<boolean>(false)
  const transitionInMs =
    typeof props.transitionInMs === "undefined" ? 300 : props.transitionInMs

  const c = useStyles({ moved, transitionInMs })

  const validViews = props.views.filter((a) => !!a)

  const [currentViews, setCurrentViews] =
    React.useState<React.ReactNode[]>(validViews)

  React.useEffect(() => {
    if (currentViews.length > 1) {
      setTimeout(() => {
        setMoved(() => true)
      }, 0)
    }
  }, [])

  useOnChange({
    value: validViews.length > currentViews.length,
    onChange(increased) {
      if (!increased) return

      setCurrentViews(() => validViews)

      process.nextTick(() => {
        setMoved(() => true)
      })
    },
  })

  useOnChange({
    value: validViews.length < currentViews.length,
    onChange(reduced) {
      if (!reduced) return

      setMoved(() => false)

      setTimeout(() => {
        setCurrentViews(() => validViews)
      }, transitionInMs + 10)
    },
  })

  return (
    <div
      className={clsx("views-with-transition", c.root, props.className)}
      style={props.style}
    >
      {currentViews
        .filter((a) => !!a)
        .map((a, index) => React.cloneElement(<>{a}</>, { key: index }))}
    </div>
  )
}
type Style = { moved: boolean; transitionInMs: number }

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      display: "flex",
      overflow: "hidden",
      "&>*": {
        flexBasis: "100%",
        flexShrink: 0,
        height: "100%",
        width: "100%",
      },

      "&>*:first-child": {
        marginLeft: (props: Style) => {
          return props.moved ? "-100%" : "0%"
        },
        transition: (props: Style) =>
          ["margin-left ", props.transitionInMs, "ms"].join(""),
      },
      // "&>*:first-child": {
      //   marginLeft: (props: Style) => {
      //     return props.moved ? "-100%" : "0%"
      //   },
      //   transition: (props: Style) =>
      //     ["margin-left ", props.transitionInMs, "ms"].join(""),
      // },
    },
  }
})
