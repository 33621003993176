import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import ImageAttachment from "components/ImageAttachment/ImageAttachment"
import LinkButton from "components/LinkButton/LinkButton"
import ShopArticleCard from "components/ShopArticleCard/ShopArticleCard"
import WindowSize from "components/WindowSize/WindowSize"
import I18nContext from "contexts/i18nContext/i18nContext"
import {
  AttachmentFragment,
  Attachment_FileAttachment_Fragment,
  Attachment_PageBoardItemAttachment_Fragment,
  ImageAttachmentFragment,
  Message_TextMessage_Fragment,
} from "lib/graphql/operations"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import { PageBoardItemAttachmentFragment } from "../../lib/graphql/operations"
import { PdfAttachment } from "../PdfAttachment/PdfAttachment"

function MessageAttachments(props: {
  message: Message_TextMessage_Fragment
  onClickAttachment?: (attachment: AttachmentFragment) => void
  renderPageBoardItemAttachment?: (props: {
    attachment: PageBoardItemAttachmentFragment
  }) => React.ReactNode
}) {
  const files = props?.message?.attachments

  const c = useStyles({ isClickable: !!props.onClickAttachment })

  if (!files?.length) return null

  const basicHeight = 200

  const isFileAttachment = (
    a: AttachmentFragment
  ): a is Attachment_FileAttachment_Fragment =>
    a.__typename === "FileAttachment" && a?.file.src

  const isPageBoardItemAttachment = (
    a: AttachmentFragment
  ): a is Attachment_PageBoardItemAttachment_Fragment =>
    a.__typename === "PageBoardItemAttachment"

  const components = files
    .map((a, index) => {
      if (!a) return null

      let children: React.ReactNode = null

      if (isFileAttachment(a)) {
        if (a.file.mimetype?.includes("pdf"))
          children = (
            <PdfAttachment.Provider height={basicHeight} attachment={a}>
              <PdfAttachment.Root></PdfAttachment.Root>
            </PdfAttachment.Provider>
          )
        else {
          children = (
            <ImageAttachment.Provider src={a.file.src} height={basicHeight}>
              <ImageAttachment.Root />
            </ImageAttachment.Provider>
          )
        }
      } else if (
        (a as ImageAttachmentFragment).__typename === "ImageAttachment"
      ) {
        const img = (a as ImageAttachmentFragment).image

        const aspect = img.aspect

        children = (
          <WindowSize.Context.Consumer>
            {(size) => {
              const height = (() => {
                if (size.height < 700) return basicHeight
                return (aspect || 0) < 1 ? 300 : basicHeight
              })()

              return (
                <ImageAttachment.Provider
                  src={img.src}
                  height={height}
                  aspect={aspect || undefined}
                >
                  <ImageAttachment.Root />
                </ImageAttachment.Provider>
              )
            }}
          </WindowSize.Context.Consumer>
        )
      } else if (isPageBoardItemAttachment(a)) {
        if (props.renderPageBoardItemAttachment)
          return props.renderPageBoardItemAttachment({ attachment: a })

        if (a.item.__typename !== "ShopArticlePageBoardItem") return null

        if (!a.item.article) return null

        children = (
          <ShopArticleCard
            article={a.item.article}
            style={{ width: "100%" }}
          ></ShopArticleCard>
        )
      }

      if (!children)
        return (
          <I18nContext.Consumer>
            {(a) => {
              return (
                <LinkButton
                  iconName="browser_not_supported"
                  description={a.attachmentNotFound}
                ></LinkButton>
              )
            }}
          </I18nContext.Consumer>
        )

      return (
        <div
          className={clsx("attachment-wrap", c.clickable)}
          key={index}
          onClick={() => props.onClickAttachment?.(a)}
        >
          {children}
        </div>
      )
    })
    .filter((a): a is JSX.Element => !!a)

  return <div className={clsx("attachments", c.attachments)}>{components}</div>
}

export default React.memo(
  MessageAttachments,
  arePropsEqual([(a) => a?.message?.attachments])
)

const useStyles = makeStyles((theme) => {
  return {
    attachments: {
      flexWrap: "wrap",
      display: "flex",
      "&>*": {
        // flexBasis: 200,
        flex: 1,
        marginBottom: theme.spacing(2),
        paddingRight: 4,
      },
    },

    clickable: (props: { isClickable: boolean }) =>
      props.isClickable ? { cursor: "pointer" } : {},
  }
})
