import React from "react"
import _ from "lodash"
import {
  ContentState,
  Editor,
  ContentBlock,
  EditorState,
  CompositeDecorator,
} from "draft-js"
import contentStateFromString from "util/draftJS/contentStateFromString"
import clsx from "clsx"
import { convertToRaw, convertFromRaw, RawDraftContentState } from "draft-js"

export default function RichText(props: {
  value?: string | RawDraftContentState | null
  className?: string
  decorator?: CompositeDecorator
  onChange?: (a: RawDraftContentState) => void
  style?: React.CSSProperties
  EditorProps?: Omit<
    React.ComponentProps<typeof Editor>,
    "editorState" | "onChange"
  >
}) {
  const [editorState, setEditorState] = React.useState(
    getEditorState(props.value, props.decorator)
  )

  const propsOnChange = React.useCallback(
    _.debounce(function ok(p: {
      editorState: EditorState
      value: string | null | undefined | RawDraftContentState
    }) {
      const rawState = convertToRaw(getContentState(p.value))

      const { areDifferent, editorRawState } = compareRawStates({
        editorState: p.editorState,
        value: rawState,
      })

      if (areDifferent) {
        props.onChange?.call(undefined, editorRawState)
      }
    },
    300),
    [props.onChange]
  )

  const onChange = React.useCallback(
    (editorState: EditorState) => {
      setEditorState(editorState)

      process.nextTick(() => {
        propsOnChange({ editorState, value: props.value })
      })
    },
    [setEditorState, propsOnChange]
  )

  return (
    <div className={clsx("rich-text", props.className)} style={props.style}>
      <Editor
        editorState={editorState}
        onChange={onChange}
        {...(props.EditorProps || {})}
      ></Editor>
    </div>
  )
}

function getEditorState(
  richText: string | RawDraftContentState | undefined | null,
  decorator?: CompositeDecorator
) {
  const contentState = getContentState(richText)
  return EditorState.createWithContent(contentState, decorator)
}

function getContentState(
  richText: string | RawDraftContentState | undefined | null
) {
  const contentState = (() => {
    if (!richText) {
      return ContentState.createFromText("")
    }

    if (typeof richText === "string") {
      const contentState =
        contentStateFromString(richText) ||
        ContentState.createFromText(richText || "", "\n")

      return contentState
    }

    return convertFromRaw(richText)
  })()

  return contentState
}

export function findWithRegex(
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) {
  const text = contentBlock.getText()

  let matchArr, start

  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    callback(start, start + matchArr[0].length)
  }
}

function compareRawStates(props: {
  editorState: EditorState
  value: string | RawDraftContentState | null | undefined
}) {
  const editorRawState = convertToRaw(props.editorState.getCurrentContent())

  const valueRawState = convertToRaw(getContentState(props.value))

  return {
    editorRawState,
    valueRawState,
    areDifferent:
      JSON.stringify(editorRawState) !== JSON.stringify(valueRawState),
  }
}
