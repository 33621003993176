import React from "react"
import clsx from "clsx"
import { RepliedMessageListItemFragment } from "lib/graphql/operations"
import MessagesContext from "components/Room/components/Messages/contexts/MessagesContext"
import requestFrame from "lib/requestFrame"
import TextMessagePreview from "components/Room/components/MessagePreview/TextMessagePreview"

export function RepliedMessage(props: {
  className: string
  repliedMessageListItem: RepliedMessageListItemFragment
}) {
  const { listRenderer } = React.useContext(MessagesContext)

  if (props.repliedMessageListItem?.message?.__typename !== "TextMessage")
    return null

  return (
    <div
      className={clsx("replied-message", props.className)}
      onClick={(e) => {
        e.stopPropagation()

        requestFrame(() => {
          listRenderer.scrolling.scrollToItem({
            index: props.repliedMessageListItem.index,
          })
        })
      }}
    >
      <TextMessagePreview message={props.repliedMessageListItem.message} />
    </div>
  )
}
