import { RoomContext } from "components/Room/contexts/room"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  MessageListItemFragment,
  useSearchMessageListItemsQuery,
} from "lib/graphql/operations"
import React from "react"

export default function useSearchMessageListItems(props: { search: string }) {
  const { workspaceID } = useActiveResources()
  const { roomID } = React.useContext(RoomContext)

  const query = useSearchMessageListItemsQuery({
    skip: !workspaceID || !roomID || !props.search,
    fetchPolicy: "no-cache",
    variables: {
      roomID: roomID || "",
      workspaceID: workspaceID || "",
      search: props.search,
    },
  })

  return useMemoAPI({
    list: query.data?.searchMessageListItems,
    // list: sample,
    isLoading: query.loading,
  })
}

const sample: MessageListItemFragment[] = [
  {
    index: 474,
    roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
    message: {
      id: "EN2jDbeBFnZ8nzmka",
      sentAt: "2021-01-25T16:33:24.898Z",
      roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
      editedAt: "2021-01-25T16:34:04.562Z",
      deletedAt: null,
      reactions: null,
      author: {
        id: "434442482d413548352d344331312d48454c4c2d4f4e4341",
        name: "Hellon Canella",
        __typename: "User",
      },
      __typename: "TextMessage",
      content: "I've sent it to Oxana",
      attachments: null,
      repliedMessageListItem: {
        index: 471,
        roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
        message: {
          id: "y8hHBJpuiG6fd8NNJ",
          sentAt: "2021-01-25T16:31:33.532Z",
          roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
          editedAt: null,
          deletedAt: null,
          reactions: null,
          author: {
            id: "343143382d414643412d394538372d34303330",
            name: "Ulrich Frotscher",
            __typename: "User",
          },
          __typename: "TextMessage",
          content: "pls provide a new bank account data!",
          attachments: null,
        },
        __typename: "MessageListItem",
      },
    },
    __typename: "MessageListItem",
  },
  {
    index: 257,
    roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
    message: {
      id: "XeTTeShbn2Dc7fTKM",
      sentAt: "2021-01-12T11:20:13.085Z",
      roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
      editedAt: "2021-01-12T11:20:27.053Z",
      deletedAt: null,
      reactions: null,
      author: {
        id: "434442482d413548352d344331312d48454c4c2d4f4e4341",
        name: "Hellon Canella",
        __typename: "User",
      },
      __typename: "TextMessage",
      content: "hi. I've submited them... but they are not visible to Oxana",
      attachments: null,
      repliedMessageListItem: null,
    },
    __typename: "MessageListItem",
  },
  {
    index: 123,
    roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
    message: {
      id: "2v9FWsm8gp6wokB2s",
      sentAt: "2020-12-23T11:52:23.834Z",
      roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
      editedAt: null,
      deletedAt: null,
      reactions: null,
      author: {
        id: "343143382d414643412d394538372d34303330",
        name: "Ulrich Frotscher",
        __typename: "User",
      },
      __typename: "TextMessage",
      content: "and if you have a chance, Oxana is asking for Oct+Nov invoices",
      attachments: null,
      repliedMessageListItem: null,
    },
    __typename: "MessageListItem",
  },
  {
    index: 481,
    roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
    message: {
      id: "6FQCW4LFQfZ8SueeR",
      sentAt: "2021-01-25T16:37:38.775Z",
      roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
      editedAt: null,
      deletedAt: null,
      reactions: null,
      author: {
        id: "343143382d414643412d394538372d34303330",
        name: "Ulrich Frotscher",
        __typename: "User",
      },
      __typename: "TextMessage",
      content: "add this to your email pls. I make sure Oxana wires ASAP",
      attachments: null,
      repliedMessageListItem: null,
    },
    __typename: "MessageListItem",
  },
  {
    index: 477,
    roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
    message: {
      id: "QA6r2PTccdyTPYrDv",
      sentAt: "2021-01-25T16:34:59.361Z",
      roomID: "Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le",
      editedAt: null,
      deletedAt: null,
      reactions: null,
      author: {
        id: "343143382d414643412d394538372d34303330",
        name: "Ulrich Frotscher",
        __typename: "User",
      },
      __typename: "TextMessage",
      content:
        "pls drop an email to oxana@10able.de, cc ulli@10able.de on above message",
      attachments: null,
      repliedMessageListItem: null,
    },
    __typename: "MessageListItem",
  },
]
