import { useCallback } from "react"
import sessionID from "lib/setupApollo/sessionID"
import { getActiveWorkspaceID } from "lib/activeWorkspaceID"
import useActiveResources from "hooks/useActiveResources"

export const cache: { [key: string]: string | undefined } = {}

export default function useFetchImageWithAuth() {
  const { workspaceID } = useActiveResources()

  const fetchImage = useCallback(
    (src: string): Promise<string | null> => {
      if (cache[src]) return Promise.resolve(cache[src] || "")

      return fetch(src, {
        headers: {
          "session-id": sessionID,
          "active-workspace-id": workspaceID || getActiveWorkspaceID() || "",
        },
      })
        .then((response) => {
          const blob = response.blob()
          return blob
        })
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob)

          cache[src] = objectURL

          return objectURL
        })
    },
    [workspaceID]
  )

  return fetchImage
}
