import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core"
import React from "react"
import { StyledProps } from "types/type"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { AssistantInspectContext } from "./AssistantInspectContext"

export function Body(props: StyledProps) {
  const [expandedIds, setExpandedIds] = React.useState(["thoughts", "question"])

  return (
    <div {...props}>
      <AssistantInspectContext.Consumer>
        {(a) => {
          return (
            <div style={{ padding: 16, paddingTop: 8 }}>
              {a.items.map((b, index) => {
                return (
                  <Accordion
                    expanded={expandedIds.includes(b.id)}
                    onChange={(_, expanded) => {
                      if (expanded) {
                        setExpandedIds((a) => [...a, b.id])
                      }

                      if (!expanded) {
                        setExpandedIds((a) => a.filter((c) => c !== b.id))
                      }
                    }}
                  >
                    <AccordionSummary
                      key={index}
                      expandIcon={<ExpandMoreIcon />}
                      id={b.id}
                    >
                      <Typography>{b.label}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>{b.component}</AccordionDetails>
                  </Accordion>
                )
              })}
            </div>
          )
        }}
      </AssistantInspectContext.Consumer>
    </div>
  )
}
