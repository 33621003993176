import React from "react"
import _ from "lodash"
import { ContentBlock, CompositeDecorator } from "draft-js"
import { urlRegex, localhostRegex } from "lib/constants"
import SameOriginLink from "components/SameOriginLink/SameOriginLink"
import RichText, { findWithRegex } from "components/RichText/RichText"
import arePropsEqual from "util/arePropsEqual"
import uniqid from "uniqid"
import useOnChange from "hooks/useOnChange"
import { ShareableType } from "types/type"
import LinkToTheCardButton from "../../../../../LinkToTheCardButton/LinkToTheCardButton"

const MemoizedRichText = React.memo(RichText, arePropsEqual(["value"]))

export default function MessageRichText(
  props: Omit<React.ComponentProps<typeof RichText>, "decorator">
) {
  const [key, setKey] = React.useState(uniqid())

  useOnChange({
    value: JSON.stringify(props.value),
    onChange() {
      setKey(uniqid())
    },
  })

  return (
    <MemoizedRichText
      {...props}
      decorator={decorator}
      EditorProps={{ readOnly: true }}
      key={key}
    ></MemoizedRichText>
  )
}

export const decorator = new CompositeDecorator([
  {
    component: URLAnchor,
    strategy: function urlStrategy(
      contentBlock: ContentBlock,
      callback: (start: number, end: number) => void
    ) {
      findWithRegex(localhostRegex, contentBlock, callback)
      findWithRegex(urlRegex, contentBlock, callback)
    },
  },
])

function URLAnchor(props: {
  children: React.ReactChildren
  decoratedText: string
}) {
  const sameOrigin = (() => {
    try {
      const url = new URL(props.decoratedText)
      return url.origin === window.origin
    } catch (e) {
      return false
    }
  })()

  if (sameOrigin) {
    const url = new URL(props.decoratedText)
    return (
      <SameOriginLink
        url={url}
        renderShareableLink={(api) => {
          if (api.shareableProps?.type === ShareableType.KanbanCard) {
            return (
              <LinkToTheCardButton
                card={{
                  id: api.shareableProps.shareable.id,
                  title: api.shareableProps.shareable.name || "",
                }}
              />
            )
          }

          return api.defaultComponent
        }}
      ></SameOriginLink>
    )
  }

  return (
    <a href={props.decoratedText} target="_blank">
      {props.children}
    </a>
  )
}
