import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { ShopContext } from "components/Shop/ShopContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { StyledProps } from "types/type"
import { OutlinedButtonWithIconAndTextCentered } from "../../../OutlinedButtonWithIconAndTextCentered"

export function CartButtons(props: StyledProps & { articleID: string }) {
  const c = useStyles()

  const { changeQuantity, remove, getItemQuantity } =
    React.useContext(ShopContext).cartApi

  const quantity = getItemQuantity({ articleID: props.articleID })

  const t = useI18n()

  const emptyCartButton = (
    <CartButton
      icon="shopping_cart"
      text={t.addToTheCart}
      variant="outlined"
      onClick={function addToOpenOrder() {
        changeQuantity.action({ articleID: props.articleID, quantity: 1 })
      }}
    ></CartButton>
  )

  const isLoading = false

  const filledCartButtons = (!isLoading || quantity >= 1) && (
    <>
      <CartButton
        icon="remove_shopping_cart"
        text={t.removeFromCart}
        onClick={function removeFromOpenOrder() {
          remove.action({ articleID: props.articleID })
        }}
        variant="outlined"
      ></CartButton>
    </>
  )

  const show = typeof quantity === "number" && quantity > 0

  return (
    <div className={clsx(c.cartButtons, props.className)}>
      {show ? filledCartButtons : emptyCartButton}
    </div>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    cartButtons: {
      "&>*": {
        width: "100%",
        "&:not(:last-child)": {
          // marginBottom: theme.spacing(1),
        },
      },
    },
    link: {
      display: "block",
      "&>*": {
        width: "100%",
      },
    },
  }
})

const CartButton = OutlinedButtonWithIconAndTextCentered
