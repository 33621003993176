import { makeStyles, useTheme } from "@material-ui/core"
import LightTooltip from "components/LightTooltip/LightTooltip"
import Notifications from "components/Notifications/Notifications"
import { WorkspaceBadgeProps } from "components/Notifications/components/WorkspaceBadge/WorkspaceBadge"
import WorkspaceBall from "components/WorkspaceBall/WorkspaceBall"
import {
  GuestWorkspaceFragment,
  WorkspaceFragment,
} from "lib/graphql/operations"
import React from "react"
import { NavLink } from "react-router-dom"
import { ballProps } from "./ballProps"

export default function WorkspaceIcon(props: {
  workspace: WorkspaceFragment | null | undefined
  isActiveWorkspace: boolean
}) {
  const { workspace: w, isActiveWorkspace } = props

  const c = useStyles({})
  const theme = useTheme()

  const badgeProps = React.useMemo(() => {
    const a: WorkspaceBadgeProps["badgeProps"] = {
      classes: {
        root: c.badgeRoot,
        badge: c.notificationDot,
      },
    }

    return a
  }, [c.notificationDot])

  if (!w) return null

  const isGuest = w.type === "GUEST"

  const accountID = w?.account?.id
  const navLink = (
    <NavLink
      to={
        isGuest
          ? `/account/${accountID}/guest/${w?.user?.id}`
          : `/account/${accountID}`
      }
      // title={g("name")}
      className="workspace"
    >
      <LightTooltip
        placement="right"
        title={
          <>
            {isGuest
              ? `${
                  (w as GuestWorkspaceFragment).partner?.name || ""
                } zu Gast bei ${w?.name}`
              : w?.name}
          </>
        }
      >
        <div>
          <WorkspaceBall
            className={c.workspaceBall}
            name={w?.name}
            {...(() => {
              const style = ballProps()

              return {
                ...style,

                borderRadius: isGuest ? "50%" : "25%",
                background: isActiveWorkspace
                  ? theme.palette.secondary.main
                  : style.background,
              }
            })()}
          />
        </div>
      </LightTooltip>
    </NavLink>
  )

  return (
    <Notifications.WorkspaceBadge workspaceID={w.id} badgeProps={badgeProps}>
      {navLink}
    </Notifications.WorkspaceBadge>
  )
}

const useStyles = makeStyles((theme) => {
  const selectedStyle = {
    background: `${theme.palette.secondary.main} !important`,
  }

  return {
    badgeRoot: {
      display: "block",
    },
    workspaceBall: {
      "&:hover": selectedStyle,
    },
    notificationDot: {
      position: "absolute",
      top: 1,
      right: 0,
    },
  }
})
