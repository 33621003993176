import React from "react"
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel
} from "@material-ui/core"

export default function Head({ columns, orderBy, order, onClickLabel }) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((c, index) => {
          return (
            <TableCell {...c} key={`header-${index}`}>
              <TableSortLabel
                active={c.name === orderBy}
                direction={order}
                onClick={
                  typeof onClickLabel === "function"
                    ? () => onClickLabel({ columnName: c.name })
                    : () => null
                }
              >
                {c.label}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
