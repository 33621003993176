import { makeStyles } from "@material-ui/core"
import { AppCard } from "components/AppCard/AppCard"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import React from "react"
import { Body } from "./Body"
import { Header } from "./Header"
import { ItemGridCellProps, ItemGridCellProvider } from "./ItemGridCellContext"
import useImageWithAuth from "hooks/useImageWithAuth/useImageWithAuth"

export { Body as ItemGridCellBody }

export function ItemGridCell(props: ItemGridCellProps) {
  const type = props.entry?.type

  const api = React.useContext(ItemsListContext)
  const src =
    props.imageThumbnailUrl ||
    props.entry?.thumbnail?.src ||
    props.entry?.thumbnail?.url ||
    null

  const propsImageSrc = usePropsImage({
    src: src,
    disabled: !src,
  })

  const c = useStyles()

  if (!props.entry || !type) return null

  return (
    <ItemGridCellProvider
      {...props}
      key={props.entry.id}
      imageThumbnailUrl={
        propsImageSrc ||
        // api.imagesApi.getEntryImageSrc(props.entry) ||
        undefined
      }
      onNewItemThumbnail={(a) => {
        // console.log(a, "a")
        api.newThumbnailImage$.next({ ...a, item: props.entry })
      }}
    >
      <AppCard
        className={c.root}
        onClick={() => api.openItemPage(props.entry)}
        header={<Header></Header>}
        body={<Body></Body>}
        {...props.AppCardProps}
      ></AppCard>
    </ItemGridCellProvider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
    },
  }
})

function usePropsImage(props: {
  src: string | undefined | null
  disabled: boolean
}) {
  // const api = React.useContext(ItemsListContext)
  const img = useImageWithAuth({ src: props.src || "", lazyFetching: false })

  if (props.src) {
    console.log(img.objectURL, props.src)
  }

  // React.useEffect(() => {
  //   if (props.disabled) return

  //   if (props.src) {
  //     api.imagesApi.fetchImage(props.src)
  //   }
  // }, [props.disabled, props.src])

  return !!props.disabled || !props.src ? null : img.objectURL
  // : api.imagesApi.getImageObjectURL(props.src)
}
