import { makeStyles } from "@material-ui/core"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import { UserInclusionMessage } from "./UserInclusionMessage"
import KanbanLogMessage from "./components/KanbanLogMessage/KanbanLogMessage"
import { MessageBalloonProps } from "./components/MessageBalloon/MessageBalloon"
import TextMessage from "./components/TextMessage/TextMessage"
import { RoomContext } from "components/Room/contexts/room"

export default React.memo(
  Message,
  arePropsEqual([
    (a) => JSON.stringify(a.message),
    "showAuthorName",
    "isAuthorMessage",
  ])
)

function Message(props: Omit<MessageBalloonProps, "children">) {
  const { message, ...otherBalloonProps } = props

  const api = React.useContext(RoomContext)

  let content: React.ReactNode = null

  const c = useStyles()

  if (message?.__typename === "KanbanCardLogMessage")
    content = (
      <KanbanLogMessage
        message={message}
        MessageBalloonProps={{
          ...otherBalloonProps,
          style: {
            ...(otherBalloonProps.style || {}),
            ...(() => {
              const width = api.template.comfortableMessageWidth

              if (width) return { maxWidth: width }

              return {}
            })(),
          },
        }}
      ></KanbanLogMessage>
    )

  if (message?.__typename === "TextMessage") {
    content = (
      <TextMessage
        message={message}
        MessageBalloonProps={otherBalloonProps}
      ></TextMessage>
    )
  }

  if (message?.__typename === "UserInclusionMessage") {
    content = (
      <UserInclusionMessage
        MessageBalloonProps={otherBalloonProps}
        message={message}
        classes={{ userCard: c.userCard }}
      ></UserInclusionMessage>
    )
  }

  // if (!content) return null

  // return (
  //   <TextSelectionKanbanCardCreation actionsPosition={props.actionsPosition}>
  //     {content}
  //   </TextSelectionKanbanCardCreation>
  // )

  return <>{content}</>
}

const useStyles = makeStyles((theme) => {
  return {
    userCard: {
      minWidth: 250,
    },
  }
})
