import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import useTrueFalse from "hooks/useTrueFalse"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import {
  MessageFragment,
  useShopAssistantMessagePromptQuery,
} from "lib/graphql/operations"
import React from "react"
import { excludeNullable } from "lib/excludeNullable"
import { Mat } from "./Mat"
import { ChatWithAssistantEntryType } from "lib/graphql/types"

export type InputProps = {
  message: MessageFragment
  onClose: () => any
}
function useAssistantAPI(props: InputProps) {
  const a = useTrueFalse(true)

  const r = useActiveResources()

  type Variables = {
    messageID: string
    workspaceID: string
  }

  const v: Variables = {
    messageID: props.message.id,
    workspaceID: r.workspaceID,
  }

  const promptQuery = useShopAssistantMessagePromptQuery({
    skip: Object.values(v).some((v) => !v),
    variables: v,
    fetchPolicy: "cache-and-network",
  })

  const b = promptQuery?.data?.shopAssistantMessagePrompt
  const question = b?.["question"] || ""
  const thoughts = b?.["llmThoughts"] || ""
  const prompt = b?.["prompt"] || ""
  const history = b?.["historyUsed"] || []

  console.log(history)

  const content = [
    !!question && {
      id: "question",
      label: "Question",
      component: <Mat text={question}></Mat>,
    },

    !!history && {
      id: "history",
      label: "History",
      component: (
        <Mat
          text={(() => {
            const l = [...history]
              .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
              .map((a) => {
                return [
                  a.entity === ChatWithAssistantEntryType.User
                    ? "User"
                    : "Assistant",
                  '"' + a.message + '"',
                ].join(": ")
              })
              .join("\n\n")

            return l
          })()}
        ></Mat>
      ),
    },
    !!thoughts && {
      id: "thoughts",
      label: "Thoughts",
      component: <Mat text={thoughts}></Mat>,
    },
    !!prompt && {
      id: "prompt",
      label: "Prompt",
      component: <Mat text={prompt}></Mat>,
    },
  ].filter(excludeNullable)

  return useMemoAPI({
    ...props,
    items: content,
  })
}
const F = contextAndProviderFactory({
  hookApi: useAssistantAPI,
})

export const AssistantInspectContext = F.Context
export const AssistantInspectProvider = F.Provider
