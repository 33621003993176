import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { MainForm } from "../../MainForm/MainForm"
import useOpenClose from "hooks/useOpenClose"
import CollectionsInputPageProvider from "../CollectionsInputPageProvider/CollectionsInputPageProvider"
import AddNewPartner from "../AddNewPartner/AddNewPartner"
import ViewsWithTransition from "components/ViewsWithTransition/ViewsWithTransition"

export default function CollectionsInputPageDefault(props: {
  // title?: string
  style?: React.CSSProperties
  onClose?: () => any
  className?: string
}) {
  const c = useStyles()

  const oc = useOpenClose()

  return (
    <CollectionsInputPageProvider>
      <ViewsWithTransition
        className={clsx(props.className, c.viewController)}
        views={[
          <MainForm
            title="Collections"
            style={props.style}
            onClose={props.onClose}
            className={c.view}
            openAddNewPartnerForm={oc.open}
          ></MainForm>,
          oc.isOpen && <AddNewPartner onClose={oc.close}></AddNewPartner>,
        ]}
      ></ViewsWithTransition>
    </CollectionsInputPageProvider>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    viewController: {
      height: "100%",
    },
    view: {
      height: "100%",
    },
  }
})
