import { Typography } from "@material-ui/core"
import I18nContext, { I18nAPI } from "contexts/i18nContext/i18nContext"
import {
  KanbanActivityFragment,
  KanbanActivity_KanbanAddCardActivity_Fragment,
  KanbanActivity_KanbanAddCommentActivity_Fragment,
  KanbanActivity_KanbanAddImageActivity_Fragment,
  MessageFragment,
  Message_KanbanCardLogMessage_Fragment,
} from "lib/graphql/operations"
import LinkToTheCardButton from "../Messages/components/LinkToTheCardButton/LinkToTheCardButton"
import { Preview, PreviewProps } from "./Preview"
import KanbanLogMessage from "../Messages/components/MessageRow/components/Message/components/KanbanLogMessage/KanbanLogMessage copy"
import React from "react"
import { MoveCardBetweenColumns } from "../Messages/components/MessageRow/components/Message/components/KanbanLogMessage/components/DeleteCard/DeleteCard"

export function KanbanLogMessagePreview(props: { message: MessageFragment }) {
  const message = props.message

  if (message.__typename !== "KanbanCardLogMessage") return null

  if (!message.activity) return null

  const preview = getPreview(message)?.[message.activity.__typename]

  return preview || null
}

const getPreview = (
  message: Message_KanbanCardLogMessage_Fragment
): Record<KanbanActivityFragment["__typename"], React.ReactNode> => {
  // console.log(message.activity?.__typename || "")
  return {
    KanbanMoveCardBetweenColumnsActivity: (() => {
      if (!message.activity) return null
      return (
        <KanbanMessagePreview
          message={message}
          description={(t) => {
            if (!message.activity) return null
            return (
              <MoveCardBetweenColumns
                cardLink={null}
                activity={message.activity}
              ></MoveCardBetweenColumns>
            )
          }}
        ></KanbanMessagePreview>
      )
    })(),
    KanbanAddCardActivity: (
      <KanbanMessagePreview
        message={message}
        description={(t) => t["iveCreatedATask"]}
      ></KanbanMessagePreview>
    ),
    KanbanAddCommentActivity: (
      <KanbanMessagePreview
        message={message}
        description={() =>
          (message.activity as KanbanActivity_KanbanAddCommentActivity_Fragment)
            .comment?.text
        }
      ></KanbanMessagePreview>
    ),
    KanbanAddImageActivity: (() => {
      if (!message.activity) return null
      const activity =
        message.activity as KanbanActivity_KanbanAddImageActivity_Fragment

      if (activity.__typename !== "KanbanAddImageActivity") return null

      return (
        <KanbanMessagePreview
          message={message}
          src={activity.image?.src || ""}
          description={(t) => t["image"]}
        ></KanbanMessagePreview>
      )
    })(),
    KanbanChangeCardDescriptionActivity: null,
    KanbanChangeCardTitleActivity: null,
    KanbanDeleteCardActivity: null,
    KanbanRemoveImageActivity: null,
  }
}
const getPreviewProps = (
  message: Message_KanbanCardLogMessage_Fragment
): Record<KanbanActivityFragment["__typename"], PreviewProps | null> => {
  return {
    KanbanMoveCardBetweenColumnsActivity: null,
    KanbanAddCardActivity: {
      description: (
        <Typography
          variant="body2"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span style={{ fontWeight: 500, opacity: 0.8 }}>
            {(message.author?.name || "").replace(/\s+/, " ").split(" ")[0] +
              ": "}
          </span>
          <span style={{ marginLeft: 4 }}></span>
          <I18nContext.Consumer>
            {(t) => t["iveCreatedATask"]}
          </I18nContext.Consumer>
        </Typography>
      ),
      title: (
        <Typography
          variant="caption"
          style={{ display: "flex", alignItems: "center" }}
        >
          <LinkToTheCardButton
            card={message.card || null}
          ></LinkToTheCardButton>
        </Typography>
      ),
      src: "",
    },
    KanbanAddCommentActivity: null,
    KanbanAddImageActivity: null,
    KanbanChangeCardDescriptionActivity: null,
    KanbanChangeCardTitleActivity: null,
    KanbanDeleteCardActivity: null,
    KanbanRemoveImageActivity: null,
  };
}
function KanbanMessagePreview(props: {
  message: Message_KanbanCardLogMessage_Fragment
  description: (t: I18nAPI) => React.ReactNode
  src?: string
}) {
  const p: PreviewProps = {
    description: (
      <Typography
        variant="body2"
        style={{ display: "flex", alignItems: "center" }}
      >
        <span style={{ fontWeight: 500, opacity: 0.8 }}>
          {(props.message.author?.name || "")
            .replace(/\s+/, " ")
            .split(" ")[0] + ": "}
        </span>
        <span style={{ marginLeft: 4 }}></span>
        <I18nContext.Consumer>{props.description}</I18nContext.Consumer>
      </Typography>
    ),
    title: (
      <Typography
        variant="caption"
        style={{ display: "flex", alignItems: "center" }}
      >
        <LinkToTheCardButton
          card={props.message.card || null}
        ></LinkToTheCardButton>
      </Typography>
    ),
    src: props.src || "",
  }

  return <Preview {...p} leftRibbonColor={"#960091"} />
}
