// import path from "path"
import React from "react"
import _ from "lodash"
import { MoreVert as MoreVertIcon } from "@material-ui/icons"
import ToggleableMenu, {
  ToggleableMenuProps,
} from "../ToggleableMenu/ToggleableMenu"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useAppRouter from "hooks/useAppRouter"

export default function Settings(props: { edge: ToggleableMenuProps["edge"] }) {
  const { edge } = props
  const t = useI18n()
  const { history } = useAppRouter()
  const menuItems = React.useMemo(() => {
    return [
      {
        component: t.logout,
        onClick: () => history.push("/logoff"),
      },
    ]
  }, [])

  return (
    <ToggleableMenu edge={edge} icon={<MoreVertIcon />} menuItems={menuItems} />
  )
}
