import { IconButton } from "@material-ui/core"
import Hook from "components/Hook/Hook"
import { Icon } from "components/Icon/Icon"
import { Shop } from "components/Shop/Shop"
import { excludeNullable } from "lib/excludeNullable"
import { PageBoardItemParamsInput } from "lib/graphql/types"
import RoomPageBoardItemContext from "../../contexts/RoomPageBoardItemContext"
import { ShoppingInitialPageParams } from "../../types"
import { useShareItem } from "../../hooks/useShareItem"
import { ShoppingPage } from "../Shopping/ShoppingPage/ShoppingPage"

export function RoomShoppingPage(props: { onClose: () => void }) {
  return (
    <RoomPageBoardItemContext.Consumer>
      {(api) => {
        return (
          <ShoppingPage
            onClose={props.onClose}
            renderArticleButtons={({ item, open, cartButtons }) => [
              <Hook hook={useShareItem} hookProps={undefined}>
                {(api) => {
                  const params = [item]
                    .map((a) => {
                      if (!item.itemID || !item.type) return null

                      const input: PageBoardItemParamsInput = {
                        itemID: item.itemID,
                        type: item.type,
                      }

                      return input
                    })
                    .filter(excludeNullable)

                  if (params.length === 0) return null

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <IconButton
                        onClick={() => api.share({ params })}
                        size="small"
                        edge="start"
                      >
                        <Icon name="reply"></Icon>
                      </IconButton>

                      {cartButtons}
                    </div>
                  )
                }}
              </Hook>,
            ]}
            initialParams={{
              articleID: (
                api.initialParams?.openedPage as ShoppingInitialPageParams
              )?.initialParams?.articleID,
            }}
          ></ShoppingPage>
        )
      }}
    </RoomPageBoardItemContext.Consumer>
  )
}
