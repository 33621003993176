import clsx from "clsx"
import { ButtonBase, makeStyles, Icon } from "@material-ui/core"

import { red } from "constants/colors"
import { IconProps } from "components/Icon/Icon"

export default function LinkButton(props: LinkButtonProps) {
  const c = useStyles({})

  return (
    <ButtonBase
      className={clsx(
        c.button,
        props.disabled && c.disabled,
        !props.disabled && c.color
      )}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.iconName && <Icon className={c.icon}>{props.iconName}</Icon>}
      <span>{props.description}</span>
    </ButtonBase>
  )
}

export type LinkButtonProps = {
  description: string
  iconName?: IconProps["name"]

  className?: string
  disabled?: boolean
  onClick?: () => any
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    disabled: {
      color: theme.palette.text.secondary,
    },
    button: {
      display: "inline-flex",
      alignItems: "center",
      textDecoration: "none",
      opacity: 0.9,
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      position: "relative",
      top: -1,
      padding: 5,
      borderRadius: 4.5,
      textAlign: "left",
    },

    icon: {
      fontSize: `18px !important`,
      marginRight: 2,
    },

    color: { color: red[2] },
  }
})
